import React from "react"
import Category from "../../templates/category"
import { withTranslation } from 'react-i18next';
import { graphql } from "gatsby"

export default withTranslation()(({ pageContext, data, t }) => {
  const { edges } = data.allMarkdownRemark
  return (
    <Category title={'Yrittäjyys | Tärkeimmät vinkit jokaiselle yrittäjälle'}
      background="/images/blog_category4A.jpg"
      posts={edges} 
      meta={[
      {name:'description', content: 'Oletko aloitteleva tai jo kokenut yrittäjä? Tarjoilemme korvaamattomia vinkkejä yrittäjyyden tien jokaiseen vaiheeseen - näihin kannattaa tutustua!'}
       ]}
      hero={<div>
        <h1 className={"font_second semibold font40"}>Yrittäjyys – korvaamattomat vinkit jokaiselle yrittäjälle</h1>
          <p className="font20 padding_top20 opacity9 text">
            Yrittäjyys tuo mukanaan suloisen vapauden, mutta myös käytännön vastuita ja haasteita, joita ei palkkatyössä kohtaa. Olemme koonneet yhteen vinkkejä tavallisiin arjen pulmiin niin aloitteleville kun kokeneemmille yrittäjille, tutustu ja testaa!
          </p>
      </div>}>

    </Category>
  )
})

export const pageQuery = graphql `
  query {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { in: ["yrittajyys"] }, date: { ne: null } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            path
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
          }
        }
      }
    }
  }
`
